var defaultConfig = {
    REACT_APP_HOSTNAME: "https://ada-admin.embreo.global",
    REACT_APP_ACCOUNT_URL: "https://ada-api.embreo.global/accounts/rest",
    // REACT_APP_ACCOUNT_URL: "http://localhost:9000/rest",
    REACT_APP_BENEFITS_URL: "https://ada-api.embreo.global/benefits",
    REACT_APP_SOLUTIONS_URL: "https://ada-api.embreo.global/solutions",
    REACT_APP_NOTIFICATIONS_URL: "https://ada-api.embreo.global/notifications",
    REACT_APP_RETURNCARD_URL: "https://ada-api.embreo.global/accounts/rest/",
    REACT_APP_CLIENT_ID: "252417074396-e7pn9nl08m5p2tr2l7resn7856tdurti.apps.googleusercontent.com",
    REACT_APP_SITE_KEY: "6LeqRqYbAAAAAJ0ZneoervFX2FhDzMGrVCoo2N7n",
    REACT_APP_SENTRY_URL: "https://5f10ecba7de94bd5babf52ca73bb850d@o1343547.ingest.sentry.io/4504094167203840",
  };
  
  //overwrite defaultConfig from env
  var config = {
    development: {
      // nothing to overwrite
    },
    staging: {
      REACT_APP_ACCOUNT_URL: "https://ada-api.embreo.global/accounts/rest",
    },
    production: {
      REACT_APP_HOSTNAME: "https://partner.cara.sg",
      REACT_APP_ACCOUNT_URL: "https://api.cara.sg/accounts/rest",
      REACT_APP_BENEFITS_URL: "https://api.cara.sg/benefits",
      REACT_APP_SOLUTIONS_URL: "https://api.cara.sg/solutions",
      REACT_APP_NOTIFICATIONS_URL: "https://api.cara.sg/notifications",
      REACT_APP_RETURNCARD_URL: "https://api.cara.sg/accounts/rest/",
    },
  };
  
  const env = process.env.REACT_APP_ENV || 'development';
  console.log('Environment =', env);
  
  //overwrite defaultConfig from env
  const envConfig = config[env];
  Object.keys(envConfig).forEach((key) => {
    defaultConfig[key] = envConfig[key];
  });
  
  // Overwrite process.env
  console.log(defaultConfig);
  Object.keys(defaultConfig).forEach((key) => {
    if (!process.env[key]) {
      process.env[key] = defaultConfig[key];
    }
  });
  
  
  //migrate from config/AppConfig.js
  export default {
    ENV: defaultConfig['ENV'],
    ACCOUNT_URL: defaultConfig['REACT_APP_ACCOUNT_URL'],
    APP_NAME: 'ada-admin',
    BENEFITS_URL: defaultConfig['REACT_APP_BENEFITS_URL'],
    SOLUTIONS_URL: defaultConfig['REACT_APP_SOLUTIONS_URL'],
    NOTIFICATIONS_URL: defaultConfig['REACT_APP_NOTIFICATIONS_URL'],
    RETURNCARD_URL: defaultConfig['REACT_APP_RETURNCARD_URL'],
    CLIENT_ID: defaultConfig['REACT_APP_CLIENT_ID'],
    SITE_KEY: defaultConfig['REACT_APP_SITE_KEY'],
    ADMIN_HOSTNAME: defaultConfig['REACT_APP_HOSTNAME'],
  };

