import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import Recaptcha from './components/recaptcha';
import UserQRDetail from './containers/UserQRDetail';
import LandingPage from './containers/LandingPage';
import './language/i18n'

// always be last
import './index.css';

//Sentry setting
if(process.env.REACT_APP_ENV && ['production', 'staging'].includes(process.env.REACT_APP_ENV)){
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        environment: process.env.REACT_APP_ENV,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
<BrowserRouter> 
<App />
</BrowserRouter>, document.getElementById('root'));
registerServiceWorker();
