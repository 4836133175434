import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import { Container, Row, Col, Hidden, Visible } from "react-grid-system";
import UserQRDetail from "./UserQRDetail";

import LandingPage from "./LandingPage";
import Registation from "./Registration";
import RegistrationCG from "./registrationCG";
import FirsCgRegistration from "./registrationCG/firstCgRegistration";
import lastPageOfCgCreatePwd from "./registrationCG/lastPageOfCgCreatePwd";

import cgDetail from "./registrationCG/cgDetail";
import cgResidingAddress from "./registrationCG/CGresidingAddress";
import createNewMember from "./registrationCG/createNewMember";
import createNewPWD from "./registrationCG/createNewPWD";
import { tagNewPWDSelectRelation } from "./registrationCG/tagNewPWDSelectRelation";
import CareGiverCreateNewPWDDetail from "./registrationCG/pwdDetail";
import CareGiverCreateNewPWDResidingAddress from "./registrationCG/pwdResidingAddress";
import CareGiverCreateNewPWDScreeningQuestion from "./registrationCG/screeningQuestion";
import CareGiverCreateNewPWDResidingAddressHaveUsedService from "./registrationCG/pwdConfirmationAddress";
import CareGiverTagNewPWD from "./registrationCG/tagNewPWD";
import pwdSummaryNew from "./registrationCG/pwdSummaryNew";

import RegistrationPWD from "./registrationPWD";
import PersonDetailPWD from "./registrationPWD/personDetail";
import residingAddress from "./registrationPWD/residingAddress";
import OtpPage from "./registrationCG/otpPage";
import stepsForRegistration from "./registrationPWD/stepsForRegister";

import screeningQuestion from "./registrationPWD/screeningQuestion";

import createTagNewCG from "./registrationPWD/tagNewCG";
import createAccountCG from "./registrationPWD/createAccountCG";
import createNewCG from "./registrationPWD/createNewCG";
import createNewCGDetail from "./registrationPWD/createNewCGDetail";
import registrationCreateNewCGAddress from "./registrationPWD/createNewCGAddress";
import pwdresidingAddressTerms from "./registrationCG/pwdResidingAddressTerms";
import notificationRegistration from "./registrationPWD/confirmationRegistration";

import RegistrationPartner from "./registrationPartner/";

import initReactGA from "../lib/InitializeReactGA";
import afterRetunCard from "./afterRetunCard";
import FAQ from "./FAQ";
import TermsOfUse from "./TermsOfUse";
import PrivacyyPolicy from "./PrivacyPolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import MembershipTerms from "./MembershipTerms";
import SubmitPartner from "./registrationPartner/SigninPartner";
import SubmitCaregiver from "./registrationCG/SubmitCg";

import PartnerConfirmationPLWD from "./registrationPWD/PartnerConfirmation";
import PartnerConfirmationCG from "./registrationCG/PartnerConfirmation";

import SummaryPLWD from "./registrationPWD/SummaryPLWD";
import SampleCard from "./registrationPWD/SampleCard";

import SummaryCG from "./registrationCG/SummaryCG";
import AppStoresRedirect from "./AppStoresRedirect";
import MediaPlayerScreen from "./MediaPlayerScreen";
import ResetPassword from "./ResetPassword";

import RegistrationMoP from "./registrationMoP";
import OtpScreen from "./registrationMoP/otpScreen";

import GoToPoints from "./goToPoints";
import Detail from "./goToPoints/components/Detail";
// import ScrollToTop from "./ScrollToTop";

export class AppNavigation extends Component {
  compoenentDidMount() {
    initReactGA();
  }

  render() {
    return (
      <Router>
        {/* <ScrollToTop /> */}
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/safereturncards/:uid" component={UserQRDetail} />
        <Route exact path="/registration" component={Registation} />
        <Route
          exact
          path="/stepsRegistrationPWD"
          component={stepsForRegistration}
        />
        <Route exact path="/registrationPWD" component={RegistrationPWD} />
        <Route
          exact
          path="/signUpPersonDetailPWD"
          component={PersonDetailPWD}
        />
        <Route
          exact
          path="/registrationResidingAddressPWD"
          component={residingAddress}
        />
        <Route
          exact
          path="/registrationScreeningQuestionPWD"
          component={screeningQuestion}
        />
        <Route
          exact
          path="/registrationCreateCGorTagCG"
          component={createAccountCG}
        />
        <Route exact path="/registrationCreateNewCG" component={createNewCG} />
        <Route
          exact
          path="/registrationCreateNewCGDetail"
          component={createNewCGDetail}
        />
        <Route
          exact
          path="/registrationCreateNewCGAddress"
          component={registrationCreateNewCGAddress}
        />
        <Route
          exact
          path="/registrationTagExistingCG"
          component={createTagNewCG}
        />
        <Route
          exact
          path="/registrationNotification"
          component={notificationRegistration}
        />

        <Route exact path="/membership-terms" component={MembershipTerms} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/Terms-Of-Use" component={TermsOfUse} />
        <Route exact path="/afterRetunCard" component={afterRetunCard} />
        <Route exact path="/FAQ" component={FAQ} />

        <Route exact path="/registrationCG" component={RegistrationCG} />
        <Route
          exact
          path="/registrationCG/first"
          component={FirsCgRegistration}
        />
        <Route exact path="/registrationCG/otp" component={OtpPage} />
        <Route exact path="/CareGiverPersonDetail" component={cgDetail} />
        <Route
          exact
          path="/CareGiverResidingAddress"
          component={cgResidingAddress}
        />
        <Route
          exact
          path="/CareGiverCreateNewPWDORExisting"
          component={createNewMember}
        />
        <Route exact path="/CareGiverCreateNewPWD" component={createNewPWD} />
        <Route
          exact
          path="/CareGiverCreateNewPWDDetail"
          component={CareGiverCreateNewPWDDetail}
        />
        <Route
          exact
          path="/CareGiverCreateNewPWDResidingAddress"
          component={CareGiverCreateNewPWDResidingAddress}
        />
        <Route
          exact
          path="/CareGiverCreateNewPWDScreeningQuestion"
          component={CareGiverCreateNewPWDScreeningQuestion}
        />
        <Route
          exact
          path="/CareGiverCreateNewPWDResidingAddressHaveUsedService"
          component={pwdresidingAddressTerms}
        />
        <Route
          exact
          path="/lastPageOfCgCreatePwd"
          component={lastPageOfCgCreatePwd}
        />
        <Route
          exact
          path="/CareGiverTagNewPWD"
          component={CareGiverTagNewPWD}
        />
        <Route
          exact
          path="/tagNewPWDSelectRelation"
          component={tagNewPWDSelectRelation}
        />
        <Route exact path="/pwd-summary-new" component={pwdSummaryNew} />
        <Route
          exact
          path="/submit-caregiver/:token"
          component={SubmitCaregiver}
        />

        <Route
          exact
          path="/registration-partner"
          component={RegistrationPartner}
        />
        <Route exact path="/submit-partner" component={SubmitPartner} />

        <Route
          exact
          path="/partner-confirmation-plwd"
          component={PartnerConfirmationPLWD}
        />
        <Route
          exact
          path="/partner-confirmation-cg"
          component={PartnerConfirmationCG}
        />

        <Route exact path="/summary-plwd" component={SummaryPLWD} />
        <Route exact path="/sample-card" component={SampleCard} />

        <Route exact path="/summary-caregiver" component={SummaryCG} />

        <Route
          exact
          path="/app-stores-redirect"
          component={AppStoresRedirect}
        />
        <Route exact path="/mediaPlayer" component={MediaPlayerScreen} />

        <Route exact path="/reset-password/:token" component={ResetPassword} />

        <Route exact path="/registrationMoP" component={RegistrationMoP} />
        <Route exact path="/registrationMoP/otp" component={OtpScreen} />
        <Route exact path="/go-to-points" component={GoToPoints} />
        {/* <Route exact path="/go-to-points/landing" component={GoToPoints} /> */}
        <Route exact path="/go-to-points/detail/:id" component={Detail} />
      </Router>
    );
  }
}

export default AppNavigation;
